import {
  CHANGE_LOCALE,
  LOGIN_PASS_REQUEST,
  LOGOUT_REQUEST,
  TOGGLE_SIDE_MENU,
  UPDATE_USER_ROLE,
  REGISTER_USER_REQUEST,
  IS_SESSION_VALID_REQUEST,
  IS_USER_STATUS_REQUEST,
  ASK_FOR_OTP_FALSE,
  FORGET_PASSWORD_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  INCREASE_WATCH_TUTORIAL_COUNT,
  CONTACT_INFO_REQUEST,
  CHANGE_TABLE_VIEW,
  CHECK_APP_VERSION_REQUEST,
  VEHICLE_LOGIN_REQUEST,
  BOOKING_TABLE_CHANGE,
  RESET_LOGGED_IN_STAT,
  GET_USER_TYPE_STAT_REQUEST,
  GET_ROUTE_STAT_REQUEST,
  GET_COST_STAT_REQUEST,
  GET_SEAT_UTILIZATION_STAT_REQUEST,
  GET_SEAT_UTIL_CHART_STAT_REQUEST,
  GET_BOOKING_STAT_REQUEST,
  PERSIST_DASHBOARD_FILTERS,
  LOGIN_PASS_AZURE_REQUEST,
  LOGIN_SMS_CODE_REQUEST_AZURE,
  ADD_STAFF_REQUEST_AZURE,
} from "./constants";

export function switchLanguage({ locale }) {
  const switchLanguage = { type: CHANGE_LOCALE, locale };
  return switchLanguage;
}

export function toggleSideMenu(open) {
  return { type: TOGGLE_SIDE_MENU, open };
}

export function setUserRole(role) {
  return { type: UPDATE_USER_ROLE, role };
}

export function loginWithPassword({ lang, ...fields }) {
  const loginWithPassword = { type: LOGIN_PASS_REQUEST, lang, fields };
  return loginWithPassword;
}

export function loginWithAzure({ lang, ...fields }) {
  const loginWithAzure = {
    type: LOGIN_PASS_AZURE_REQUEST,
    lang,
    fields,
  };
  return loginWithAzure;
}

export function addStaff({ lang, ...fields }) {
  return {
    type: ADD_STAFF_REQUEST_AZURE,
    lang,
    fields,
  };
}

export function sendOTP({ country_code, phone, language }) {
  return {
    type: LOGIN_SMS_CODE_REQUEST_AZURE,
    country_code,
    phone,
    language,
  };
}

export const vehicleLogin = ({ lang, router, ...fields }) => ({
  type: VEHICLE_LOGIN_REQUEST,
  lang,
  router,
  fields,
});

export function setAskForOtpToFalse() {
  const setAskForOtpToFalse = { type: ASK_FOR_OTP_FALSE };
  return setAskForOtpToFalse;
}

export function logout({ router, ...fields }) {
  const logout = { type: LOGOUT_REQUEST, fields, router };
  return logout;
}
export const increaseWatchTutorialCount = ({ ...counts }) => ({
  type: INCREASE_WATCH_TUTORIAL_COUNT,
  counts,
});

export function register({ ...fields }) {
  const data = { type: REGISTER_USER_REQUEST, fields };
  return data;
}

export function checkIsSessionValid({ ...fields }) {
  const data = { type: IS_SESSION_VALID_REQUEST, fields };
  return data;
}
export const checkUserStatus = ({ ...fields }) => ({
  type: IS_USER_STATUS_REQUEST,
  fields,
});

export function onForgetPassword({ lang, router, ...fields }) {
  const onForgetPassword = {
    type: FORGET_PASSWORD_REQUEST,
    lang,
    router,
    fields,
  };
  return onForgetPassword;
}

export function onChangePassword({ lang, router, ...fields }) {
  const onChangePassword = {
    type: CHANGE_PASSWORD_REQUEST,
    lang,
    router,
    fields,
  };
  return onChangePassword;
}

export const contactInformation = (lang) => ({
  type: CONTACT_INFO_REQUEST,
  lang,
});

export const resetLoggedInStat = () => ({ type: RESET_LOGGED_IN_STAT });
export const changeTableView = (tableView) => ({
  type: CHANGE_TABLE_VIEW,
  tableView,
});

export const checkAppVersion = ({ ...params }) => ({
  type: CHECK_APP_VERSION_REQUEST,
  params,
});

export const setExtendedCol = (name, data) => {
  return { type: BOOKING_TABLE_CHANGE, name, data };
};

export const getUserTypeStat = ({ ...params }) => {
  return { type: GET_USER_TYPE_STAT_REQUEST, params };
};

export const getRouteStat = ({ ...params }) => {
  return { type: GET_ROUTE_STAT_REQUEST, params };
};

export const getBookingStat = ({ ...params }) => {
  return { type: GET_BOOKING_STAT_REQUEST, params };
};

export const getCostStat = ({ ...params }) => {
  return { type: GET_COST_STAT_REQUEST, params };
};

export const getSeatUtilizationStat = ({ ...params }) => {
  return { type: GET_SEAT_UTILIZATION_STAT_REQUEST, params };
};

export const getSeatUtilChartStat = ({ ...params }) => {
  return { type: GET_SEAT_UTIL_CHART_STAT_REQUEST, params };
};

export const persistFilters = ({ ...params }) => {
  return { type: PERSIST_DASHBOARD_FILTERS, params };
};
